<template>
  <b-container fluid class="user-header applicant-header">
    <div class="back-header pt-5 mt-5">
      <img src="@/assets/app-icons/left-arrow.svg" width="10px" height="15px" />
      <a @click="$router.go(-1)"> Back</a>
    </div>
    <b-row>
      <b-col class="col-md-6 col-12 user-about pt-3 pb-5">
        <div class="user-img">
          <a
            style="cursor: pointer"
            @click="
              () => {
                if ($route.name != 'Applicant-Profile') $router.push({ name: 'Applicant-Profile' });
              }
            "
          >
            <img :src="this.$store.getters.getProfileImage" class="rounded img-center" width="100" />
            <img v-if="!this.$store.getters.getProfileImage" src="../../assets/unknown.jpg" class="rounded img-center" width="100" />
          </a>
        </div>
        <div class="user-content ml-3">
          <a
            style="cursor: pointer"
            @click="
              () => {
                if ($route.name != 'Applicant-Profile') $router.push({ name: 'Applicant-Profile' });
              }
            "
          >
            <h3 class="user-name">
              {{ $store.getters.getLoggedUser.userName }}
            </h3>
            <p class="user-loc">
              {{ $store.getters.getCountry ? $store.getters.getCountry : "" }}
              <br />
              {{ $store.getters.getCity ? $store.getters.getCity.city_name : "" }}
            </p>
          </a>
        </div> </b-col
      ><!--End User About-->
      <b-col class="col-md-12 col-xl-6 user-header-nav">
        <ul class="user-nav-list">
          <li class="user-nav-item">
            <router-link class="user-nav-link" :to="{ name: 'Applicant-Profile' }">Profile</router-link>
          </li>
          <li class="user-nav-item">
            <!-- <router-link
                    class="user-nav-link"
                    :to="{ name: 'Employer-jobs' }"
                    >Jobs</router-link
                  > -->
            <router-link class="user-nav-link" :to="{ name: 'Applicant-Jobs' }">JOBS</router-link>
          </li>
          <li class="user-nav-item">
            <router-link class="user-nav-link" :to="{ name: 'Applicant-Settings' }">Settings</router-link>
          </li>
          <li class="user-nav-item">
            <router-link class="user-nav-link" to="/applicant-message">Messages</router-link>
          </li>
          <!-- <li class="user-nav-item">
            <router-link
              class="user-nav-link"
              :to="{ name: 'Applicant-Referrals' }"
              >Referrals</router-link
            >
          </li> -->
        </ul> </b-col
      ><!--End User Header Nav-->
    </b-row>
  </b-container>
</template>

<script>
export default {
  data() {
    return {
      image: null,
      name: null,
      country: null,
    };
  },

  beforeMount() {
    this.getImage();
  },
  mounted: function () {
    this.$nextTick(function () {
      this.country = this.$cookies.get("residence");
    });
  },
  methods: {
    getImage() {
      this.image = localStorage.getItem("image");
      console.log("this.image :>> ", this.image);
      this.name = this.$store.getters.getLoggedUser.userName;

      console.log("this.name, this.country :>> ", this.name, this.country);
    },
  },
};
</script>

<style></style>
