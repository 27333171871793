<template>
  <div>
    <div class="border-bottom d-flex align-items-center justify-content-between w-100" v-for="job of jobs" :key="job.job_id" style="cursor: pointer" :class="selectedJob && selectedJob.job_id == job.job_id ? 'selected' : ''">
      <div class="d-flex align-items-center my-2 mx-2 w-100" @click="onSelectJob(job)">
        <div class="company-img">
          <img v-if="job.company_logo_path" :src="job.company_logo_path" class="nav-rounded-circle" />
          <img src="@/assets/unknown.jpg" class="nav-rounded-circle" v-if="!job.company_logo_path" />
        </div>
        <div class="pl-2">
          <h5 class="mb-0">{{ job.job_title }}</h5>
          <p class="mb-0">{{ job.company_long_name }}</p>
        </div>
      </div>
      <img class="arrow-icon" width="10px" src="@/assets/right-arrow-icon.png" alt="" />
    </div>
  </div>
</template>

<script>
import ApplicantJobService from "@/apiServices/ApplicantJobServices";
export default {
  name: "AllAppliedJobs",
  props: {
    jobs: Array,
  },
  data() {
    return {
      applicants: [],
      selectedJob: null,
      // jobs: [],
      pagination: {
        user_id: null,
        showSizeChanger: true,
        current: 1,
        pageSize: 10,
        total: 0,
        searchText: "",
        feature: "Y",
      },
    };
  },
  beforeMount() {
    // this.getApplicants();
  },
  methods: {
    onSelectJob(job) {
      this.selectedJob = job;

      this.$emit("selectedJob", job);
    },
  },
};
</script>

<style>
.navbar .nav-rounded-circle {
  border-radius: 50%;
  border: 1px solid #6244e5;
}

.selected {
  background-color: #315d9b;
  color: #ffffff;
}

.company-img {
  width: 40px;
  height: 40px;
  border-radius: 20px;
  overflow: hidden;
}
.company-img img {
  object-fit: cover;
  object-position: 50% 50%;
  width: 100%;
}

.arrow-icon {
  filter: invert(98%) sepia(98%) saturate(0%) hue-rotate(321deg) brightness(102%) contrast(102%);
  margin-right: 10px;
}
</style>
