<template>
  <div class="bg-grey">
    <ApplicantHeader />
    <b-container fluid class="py-3 message-page">
      <div class="inbox-title-container rounded-lg">
        <span>INBOX</span>
      </div>
      <b-row style="margin: 0" class="py-3">
        <b-col v-if="jobs && jobs.length" class="col-md-4 bg-white rounded-lg py-3">
          <AllAppliedJobs :jobs="jobs" @selectedJob="getSelectedApp" />
        </b-col>
        <b-col v-else>
          <span>No messages found</span>
        </b-col>
        <b-col class="col-md-8" style="padding-right: 0" v-show="Object.keys(jobDetails).length">
          <div class="bg-white pb-3 rounded-lg">
            <div class="px-2 py-3 d-flex align-items-center justify-content-between border-bottom">
              <div class="d-flex align-items-center">
                <div class="top-company-img">
                  <img v-if="jobDetails.company_logo_path" :src="jobDetails.company_logo_path" width="100%" />
                  <img src="@/assets/unknown.jpg" width="100%" v-if="!jobDetails.company_logo_path" />
                </div>
                <div class="pl-2">
                  <h5 class="mb-0">{{ jobDetails.job_title }}</h5>
                  <p class="mb-0">{{ jobDetails.company_long_name }}</p>
                </div>
              </div>
              <div class="view-profile-container" @click="$router.push({ name: 'Job-Details', params: { id: job_id } })">
                <span>VIEW PROFILE</span>
                <img width="10px" src="@/assets/right-arrow-icon.png" alt="" />
              </div>
            </div>
            <div class="py-3">
              <div class="" v-for="(r, i) of msg" :key="i">
                <div class="d-flex w-100 px-2" :class="r.app_msg_status == 'sent' ? 'justify-content-end' : 'justify-content-start'">
                  <div class="text-img" v-if="r.app_msg_status != 'sent'">
                    <img v-if="jobDetails.company_logo_path" :src="jobDetails.company_logo_path" width="100%" />
                    <img src="@/assets/unknown.jpg" width="100%" v-if="!jobDetails.company_logo_path" />
                  </div>
                  <div class="d-flex flex-column" :class="r.app_msg_status == 'sent' ? 'mr-1' : 'ml-1'">
                    <div class="py-2 px-3 bg-light-dark text-message d-flex" :class="r.app_msg_status == 'sent' ? 'border-radius-left' : 'border-radius-right'">
                      <span>{{ r.message }}</span>
                    </div>
                    <span class="time mt-1">{{ convertTime(r.ago) }} ago</span>
                  </div>

                  <div class="text-img" v-if="r.app_msg_status == 'sent'">
                    <!-- <img
                      v-if="jobDetails.company_logo_path"
                      :src="jobDetails.company_logo_path"
                      width="50px"
                    />
                    <img
                      src="@/assets/unknown.jpg"
                      width="50px"
                      v-if="!jobDetails.company_logo_path"
                    /> -->
                    <img :src="$store.getters.getProfileImage" width="100%" />
                    <img v-if="!$store.getters.getProfileImage" src="@/assets/unknown.jpg" width="100%" />
                  </div>
                </div>
                <!-- {{ r.message }}
                <br />
                <span>{{ r.ago }}m</span> -->
              </div>
              <!-- <div
                class="bg-light-dark text-message margin-left"
                v-for="(m, i) of msg"
                :key="i"
              >
                {{ m.message }}
                <br />
                <span class="display-block">{{ m.ago }}m</span>
              </div> -->
            </div>
            <div class="message-box">
              <input placeholder="Type your message" type="text" class="" v-model="message" />
              <button class="btn btn-primary send-btn" @click="sendMessage">
                <img src="@/assets/app-icons/send.svg" alt="" />
              </button>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import ApplicantHeader from "@/views/Applicant-Profile-Screens/Applicant.vue";
import AllAppliedJobs from "./AllAppliedJobs.vue";
import RecruiterJobService from "@/apiServices/RecruiterJobService";
import ApplicantJobService from "@/apiServices/ApplicantJobServices";

import moment from "moment";
import { convertTime } from "@/helpers/HelperFunctions.js";
export default {
  name: "Applicant-Message",
  components: {
    ApplicantHeader,
    AllAppliedJobs,
  },
  data() {
    return {
      msg: [],
      recieved: [],
      message: null,
      app_id: null,
      job_id: null,
      job_user_id: null,
      jobDetails: {},
      interval: null,
      jobs: [],
    };
  },
  mounted() {
    this.getAllAppliedJobs();
    this.fetchAllMsg();
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
  methods: {
    convertTime: convertTime,
    getAllAppliedJobs() {
      ApplicantJobService.GetAppliedJobsForMessage().then((res) => {
        
        this.jobs = res.data.data;
        //console.log("applied jobs :>> ", this.jobs);
      });
    },
    fetchAllMsg() {
      this.interval = setInterval(() => {
        RecruiterJobService.GetApplicantMessage({
          app_id: this.job_user_id,
          job_id: this.jobDetails.job_id,
        }).then((res) => {
          
          this.msg = res.data.data.senderReceiver.map((m) => {
            const now = moment();
            const created = moment(m.created_at).format("YYYY-MM-DD HH:mm:ss");
            let diff = now.diff(created, "minutes");
            return {
              ...m,
              ago: diff,
            };
          });
        });
      }, 5000);
    },
    getSelectedApp(e) {
      console.log("e :>> ", e);
      // this.app_id = e.user_id;
      this.jobDetails = e;
      this.job_id = e.job_id;
      const app_id = this.$cookies.get("user_id");
      this.job_user_id = e.job_user_id;
      RecruiterJobService.GetApplicantMessage({
        app_id: this.job_user_id,
        job_id: e.job_id,
      }).then((res) => {
        
        this.msg = res.data.data.senderReceiver.map((m) => {
          const now = moment();
          const created = moment(m.created_at).format("YYYY-MM-DD HH:mm:ss");
          let diff = now.diff(created, "minutes");
          return {
            ...m,
            ago: diff,
          };
        });
      });
    },
    getNewMessages(e) {
      RecruiterJobService.GetApplicantMessage({
        app_id: e.job_user_id,
        job_id: e.job_id,
      }).then((res) => {
        
        this.msg = res.data.data.senderReceiver.map((m) => {
          const now = moment();
          const created = moment(m.created_at).format("YYYY-MM-DD HH:mm:ss");
          let diff = now.diff(created, "minutes");
          return {
            ...m,
            ago: diff,
          };
        });
      });
    },
    sendMessage() {
      if (!this.job_user_id && !this.job_id) {
        this.$toasted.show("please select applicant to talk", {
          duration: 6000,
          icon: "clear",
        });
        return;
      }
      if (!this.message || this.message == "") {
        return;
      }

      const payload = {
        reciever_id: this.job_user_id,
        message: this.message,
        job_id: this.job_id,
      };
      RecruiterJobService.SendMessage(payload).then((res) => {
        
        this.message = null;
        this.getNewMessages({
          job_user_id: this.job_user_id,
          job_id: this.job_id,
        });
      });
    },
  },
};
</script>

<style scoped>
.bg-light-dark {
  background: #eaeaea;
}
.text-message {
  width: 250px;
  /* margin: 0 10px; */
  overflow: hidden;
  display: block;
}
.margin-left {
  margin-left: auto;
}
.inbox-title-container {
  width: 100%;
  height: 50px;
  background-color: #ffffff;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 10px 20px;
}
.inbox-title-container span {
  font-weight: bold;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-size: 1.2rem;
}
.border-radius-right {
  border-radius: 15px;
  border-top-left-radius: 0;
}
.border-radius-left {
  border-radius: 15px;
  border-top-right-radius: 0;
}
.time {
  font-size: 0.75rem;
  opacity: 0.7;
}

.text-img {
  width: 40px;
  height: 40px;
  border-radius: 20px;
  overflow: hidden;
}
.text-img img {
  object-fit: cover;
  object-position: 50% 50%;
}
.top-company-img {
  width: 50px;
  height: 50px;
  border-radius: 25px;
  overflow: hidden;
}
.top-company-img img {
  object-fit: cover;
  object-position: 50% 50%;
}

.view-profile-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
}
.view-profile-container span {
  font-size: 1.1rem;
  font-weight: bold;
  margin-right: 10px;
}
.view-profile-container:hover {
  cursor: pointer;
}
.message-box {
  height: 40px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  background: #eaeaea;
  margin: 0 10px;
  box-sizing: border-box;
}
.message-box input {
  border: none;
  width: 100%;
  padding: 10px;
  background: #eaeaea;
}

.message-box input:focus-visible {
  outline: none;
}

.send-btn {
  width: 30px;
  height: 30px;
  border-radius: 15px;
  overflow: hidden;
  padding: 0;
  margin-right: 4px;
  /* border: 0;
  background: #6244e5; */
}

.send-btn img {
  width: 15px;
  object-fit: contain;
}
</style>
